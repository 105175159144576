import React from 'react';
import { Columns } from '@blendle/lego';
import { shape, string, node } from 'prop-types';
import moment from 'moment';
import { translate } from 'instances/i18n';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';

const ItemDetailsWrapper = styledModule('dl')(CSS.itemDetails);
const Label = styledModule('dt')(CSS.label);

function ItemDetails({ date, readingTime }) {
  return (
    <ItemDetailsWrapper>
      <Columns>
        <Label>{translate('app.manifest.time.to.read')}</Label>
        <dd title={readingTime.label}>{readingTime.copy}</dd>
      </Columns>
      <Columns>
        <Label>{translate('app.manifest.date')}</Label>
        <dd>{moment(date).format('LL')}</dd>
      </Columns>
    </ItemDetailsWrapper>
  );
}

ItemDetails.propTypes = {
  readingTime: shape({
    copy: node.isRequired,
    label: node,
  }).isRequired,
  date: string.isRequired,
};

export default ItemDetails;
