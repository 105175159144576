// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fixedSize-DvE9m{flex:0 0;align-items:center}.grow-1JLfX{flex:1 0}\n", ""]);
// Exports
exports.locals = {
	"fixedSize": "fixedSize-DvE9m",
	"grow": "grow-1JLfX"
};
module.exports = exports;
