import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'instances/i18n';

class PinButton extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    marked: PropTypes.bool.isRequired,
    showText: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  _renderText() {
    const { marked, showText } = this.props;

    if (showText) {
      return marked ? translate('app.pin.button_remove') : translate('app.pin.button');
    }

    return null;
  }

  render() {
    const { marked, onChange, className } = this.props;
    const classes = classNames('btn-pin', { marked }, className);

    return (
      <button className={classes} onClick={onChange}>
        {this._renderText()}
      </button>
    );
  }
}

export default PinButton;
