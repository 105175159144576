import React from 'react';
import { string, bool, shape, number, func } from 'prop-types';
import { DropdownOption } from '@blendle/lego';
import DropdownSharingButtonsContainer from 'containers/DropdownSharingButtonsContainer';
import PinButtonContainer from 'modules/timeline/containers/PinButtonContainer';
import ItemFeedbackOptionContainer from 'containers/ItemFeedbackOptionContainer';
import classNames from 'classnames';
import { LOCATION_IN_LAYOUT } from 'app-constants';
import moment from 'moment';
import { getReadingTime } from 'helpers/manifest';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';
import ItemDetails from '../ItemDetails';

const ManifestDropdownContent = ({
  itemId,
  analytics,
  itemLength,
  date,
  hidePin,
  showItemFeedback,
  closeDropdown,
}) => (
  <div data-testid="manifest-dropdown-options">
    {showItemFeedback && (
      <ItemFeedbackOptionContainer
        data-testid="item-feedback"
        itemId={itemId}
        onFeedback={closeDropdown}
        analytics={{
          ...analytics,
          location_in_layout: LOCATION_IN_LAYOUT.MANIFEST_DROPDOWN,
        }}
      />
    )}
    {!hidePin && (
      <DropdownOption className={classNames(CSS.dropdownOption, CSS.pinOption)}>
        <PinButtonContainer analytics={analytics} itemId={itemId} />
      </DropdownOption>
    )}
    <DropdownSharingButtonsContainer analytics={analytics} itemId={itemId} />
    <ItemDetails
      readingTime={{
        copy: moment.duration(getReadingTime(itemLength), 'minutes').humanize(),
        label: translate('app.text.nr_words', [itemLength.words]),
      }}
      date={date}
    />
  </div>
);

ManifestDropdownContent.propTypes = {
  itemId: string.isRequired,
  analytics: shape({
    internal_location: string,
  }),
  itemLength: shape({
    words: number.isRequired,
  }).isRequired,
  date: string.isRequired,
  hidePin: bool,
  showItemFeedback: bool,
  closeDropdown: func,
};

ManifestDropdownContent.defaultProps = {
  hidePin: false,
  showItemFeedback: false,
  analytics: {},
  closeDropdown: () => undefined,
};

export default ManifestDropdownContent;
