// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemPriceContainer-8ktU5{display:flex;justify-content:center;align-items:center}.itemPrice-2IjwX{font-size:13px;color:#4a5170;padding:0 7px;display:flex;opacity:1;height:25px;line-height:25px;border-radius:3px}.l-desktop .itemPrice-2IjwX{transition:border 0.25s ease-in-out}.itemPrice-2IjwX.acquired-Hh_GH{visibility:hidden}.itemPrice-2IjwX.opened-3lQFg:after{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA0B\";color:#20c576;font-size:1em;visibility:visible}.itemPrice-2IjwX.white-2vEUY{color:#fff}.itemPrice-2IjwX.white-2vEUY:after{color:#fff}.itemPrice-2IjwX.midnight-2HcGQ{color:#4a5170}.itemPrice-2IjwX.midnight-2HcGQ:after{color:#4a5170}.amount-4GMwO{color:#4a5170;border:1px solid rgba(74,81,112,0.5)}.amount-4GMwO:hover{border:1px solid #4a5170}.amount-4GMwO.white-2vEUY{border:1px solid rgba(255,255,255,0.5)}.amount-4GMwO.white-2vEUY:hover{border:1px solid white}.amount-4GMwO.midnight-2HcGQ{border:1px solid #4a5170}.premiumItem-n2R5u.subscription-7cSJA{visibility:hidden}.subscription-7cSJA{border:none;padding:0 10px;line-height:28px;background:#20c576;color:#fff;text-transform:uppercase;font-size:.78571em;border-radius:2px}.subscription-7cSJA:hover{border:none}.freeloader-3vkOF{visibility:hidden}\n", ""]);
// Exports
exports.locals = {
	"itemPriceContainer": "itemPriceContainer-8ktU5",
	"itemPrice": "itemPrice-2IjwX",
	"acquired": "acquired-Hh_GH",
	"opened": "opened-3lQFg",
	"white": "white-2vEUY",
	"midnight": "midnight-2HcGQ",
	"amount": "amount-4GMwO",
	"premiumItem": "premiumItem-n2R5u",
	"subscription": "subscription-7cSJA",
	"freeloader": "freeloader-3vkOF"
};
module.exports = exports;
