import { compose, withHandlers } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import { PREFER, STATUS_PENDING } from 'app-constants';
import UserPrefsUpdatedNotification from 'components/notifications/UserPrefsUpdatedNotification';
import ItemPreferenceStore from 'stores/ItemPreferenceStore';
import AuthStore from 'stores/AuthStore';
import ItemPreferenceActions from 'actions/ItemPreferenceActions';
import NotificationsActions from 'actions/NotificationsActions';
import ItemFeedbackOption from 'components/ManifestDropdown/ItemFeedbackOption';

function getIsLoadingItemPreferences(allPreferences, itemId) {
  const itemPreference = allPreferences[itemId];

  return Boolean(itemPreference) && itemPreference.status === STATUS_PENDING;
}

function getDidDislikeItem(allPreferences, itemId) {
  const itemPreference = allPreferences[itemId];

  if (itemPreference && itemPreference.data) {
    return itemPreference.data.preference === PREFER.LESS;
  }

  return false;
}

export function onToggleDislike(itemId, userId, allPreferences, analytics, onFeedback) {
  const didDislikeItem = getDidDislikeItem(allPreferences, itemId);

  if (didDislikeItem) {
    ItemPreferenceActions.deleteNegativePreference(userId, itemId, analytics);
  } else {
    ItemPreferenceActions.sendNegativePreference(userId, itemId, analytics);
  }

  const notificationId = `item-pref-${itemId}-${didDislikeItem}-${Date.now()}`;
  const notificationProps = {
    onClick: () => NotificationsActions.hideNotification(notificationId),
  };

  NotificationsActions.showNotification(
    UserPrefsUpdatedNotification,
    notificationProps,
    notificationId,
    {
      delay: 100,
      duration: 3000,
    },
  );

  onFeedback();
}

export function mapStateToProps({ itemPreferenceState }, { itemId }) {
  return {
    didDislikeItem: getDidDislikeItem(itemPreferenceState.preferences, itemId),
    isLoading: getIsLoadingItemPreferences(itemPreferenceState.preferences, itemId),
  };
}

mapStateToProps.stores = { ItemPreferenceStore };

const enhance = compose(
  withHandlers({
    onToggleDislike: ({ itemId, analytics, onFeedback }) => (e) => {
      e.preventDefault();

      const { preferences } = ItemPreferenceStore.getState();
      const userId = AuthStore.getState().user.id;
      onToggleDislike(itemId, userId, preferences, analytics, onFeedback);
    },
  }),
  altConnect(mapStateToProps),
);

export default enhance(ItemFeedbackOption);
