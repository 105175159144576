import React from 'react';
import { string, bool, shape, number } from 'prop-types';
import { compose, withState, withHandlers } from '@blendle/recompose';
import { Dropdown } from '@blendle/lego';
import ItemPreferenceActions from 'actions/ItemPreferenceActions';
import AuthStore from 'stores/AuthStore';
import Analytics from 'instances/analytics';
import TriggerButton from './TriggerButton';
import CSS from './style.scss';
import ManifestDropdownContent from './ManifestDropdownContent';

const enhance = compose(
  withState('isOpen', 'setOpen', false),
  withHandlers({
    toggleDropdown: ({ itemId, analytics, setOpen, isOpen }) => (willBeOpen) => {
      if (!isOpen && willBeOpen) {
        Analytics.track('Open Manifest Dropdown', {
          item: itemId,
          location_in_layout: 'manifest-dropdown',
          ...analytics,
        });

        // Fetch item feedback when user open the dropdown
        const { user } = AuthStore.getState();
        ItemPreferenceActions.fetchPreference(user.id, itemId);
      }

      setOpen(willBeOpen);
    },
    closeDropdown: ({ setOpen }) => () => {
      setOpen(false);
    },
  }),
);

/* eslint react/prop-types: ['error', { ignore: ['isOpen', 'toggleDropdown', 'closeDropdown'] }] */
function ManifestDropdown({
  itemId,
  analytics,
  itemLength,
  date,
  cappuccinoButton,
  hidePin,
  showItemFeedback,
  triggerClassName,
  isOpen, // Comes from HOC
  toggleDropdown, // Comes from HOC
  closeDropdown, // Comes from HOC
}) {
  return (
    <Dropdown
      onToggle={toggleDropdown}
      isOpen={isOpen}
      className={CSS.dropdown}
      triggerButton={
        <TriggerButton
          isCappuccinoButton={cappuccinoButton}
          isOpen={isOpen}
          className={triggerClassName}
        />
      }
    >
      <ManifestDropdownContent
        itemId={itemId}
        analytics={analytics}
        itemLength={itemLength}
        date={date}
        hidePin={hidePin}
        showItemFeedback={showItemFeedback}
        closeDropdown={closeDropdown}
      />
    </Dropdown>
  );
}

ManifestDropdown.propTypes = {
  itemId: string.isRequired,
  analytics: shape({
    internal_location: string,
  }),
  itemLength: shape({
    words: number.isRequired,
  }).isRequired,
  date: string.isRequired,
  cappuccinoButton: bool,
  hidePin: bool,
  showItemFeedback: bool,
  triggerClassName: string,
};

ManifestDropdown.defaultProps = {
  cappuccinoButton: false,
  hidePin: false,
  showItemFeedback: false,
  analytics: {},
  triggerClassName: '',
};

ManifestDropdown.displayName = 'ManifestDropdown';

export default enhance(ManifestDropdown);
