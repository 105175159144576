import React from 'react';
import { func } from 'prop-types';
import BrowserEnv from 'instances/browser_environment';
import { translate } from 'instances/i18n';
import useCookieConsent from 'hooks/useCookieConsent';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';

const Wrapper = styledModule.div(CSS.wrapper);
const ShareButton = styledModule.button(CSS.shareButton);
const Email = styledModule(ShareButton)(CSS.email);
const Facebook = styledModule(ShareButton)(CSS.facebook);
const Twitter = styledModule(ShareButton)(CSS.twitter);
const WhatsApp = styledModule(ShareButton)(CSS.whatsapp);

const DropdownSharingButtons = ({
  onShareToWhatsApp,
  onShareToEmail,
  onShareToFacebook,
  onShareToTwitter,
}) => {
  const { hasGivenConsent } = useCookieConsent();

  return (
    <Wrapper>
      <Email type="button" onClick={onShareToEmail} data-testid="share-email">
        {translate('app.manifest.share.email')}
      </Email>
      {hasGivenConsent ? (
        <Facebook type="button" onClick={onShareToFacebook} data-testid="share-facebook">
          {translate('app.manifest.share.facebook')}
        </Facebook>
      ) : null}
      <Twitter type="button" onClick={onShareToTwitter} data-testid="share-twitter">
        {translate('app.manifest.share.twitter')}
      </Twitter>
      {BrowserEnv.isMobile() ? (
        <WhatsApp type="button" onClick={onShareToWhatsApp} data-testid="share-whatsapp">
          {translate('app.manifest.share.whatsapp')}
        </WhatsApp>
      ) : null}
    </Wrapper>
  );
};

DropdownSharingButtons.propTypes = {
  onShareToWhatsApp: func.isRequired,
  onShareToEmail: func.isRequired,
  onShareToFacebook: func.isRequired,
  onShareToTwitter: func.isRequired,
};

export default DropdownSharingButtons;
