import React, { PureComponent } from 'react';
import { __ } from 'ramda';
import PropTypes from 'prop-types';
import TilesStore from 'stores/TilesStore';
import AuthStore from 'stores/AuthStore';
import { isPremiumItemTile } from 'selectors/tiles';
import ItemPrice from 'components/ItemPrice';
import AltContainer from 'alt-container';
import { FEATURE_CAPABILITY_MICROPAYMENTS } from 'app-constants';
import { capabilityIsLocked } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';

const isPriceHidden = capabilityIsLocked(__, FEATURE_CAPABILITY_MICROPAYMENTS);

class ItemPriceContainer extends PureComponent {
  static propTypes = {
    itemId: PropTypes.string,
    color: ItemPrice.propTypes.color,
    className: ItemPrice.propTypes.className,
  };

  _renderItemPrice = ({ authState, tilesState }) => {
    const { itemId, ...restProps } = this.props;
    const tile = tilesState.tiles.get(itemId);
    const { user } = authState;

    if (!tile) {
      return null;
    }

    const manifest = tile._embedded['b:manifest'];
    const isPremiumItem = isPremiumItemTile(tilesState.tiles, itemId);
    const hasProviderSubscription = user.hasActiveSubscription(manifest.provider.id);
    const itemFromSubscription =
      hasProviderSubscription || (isPremiumItem && user.hasActivePremiumSubscription());

    return (
      <ItemPrice
        price={tile.price}
        subscription={!!itemFromSubscription}
        purchased={tile.item_purchased}
        isItemOpened={!!tile.opened}
        isPremiumItem={isPremiumItem}
        issuePurchased={tile.issue_purchased}
        isFreeloader={user.isFreeloader() || false}
        {...restProps}
      />
    );
  };

  render() {
    if (isPriceHidden(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    return (
      <AltContainer
        stores={{
          tilesState: TilesStore,
          authState: AuthStore,
        }}
        render={this._renderItemPrice}
      />
    );
  }
}

export default ItemPriceContainer;
