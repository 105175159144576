import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getManifest } from 'selectors/tiles';
import ShareActions from 'actions/ShareActions';
import TilesStore from 'stores/TilesStore';
import { LOCATION_IN_LAYOUT, SHARING_PLATFORM } from 'app-constants';
import DropdownSharingButtons from 'components/DropdownSharingButtons';
import useUser from 'hooks/useUser';
import ShareToEmailContainer from 'containers/ShareToEmailContainer';

const DropdownSharingButtonsContainer = ({ itemId, analytics: incomingAnalytics }) => {
  const user = useUser();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const manifest = getManifest(TilesStore.getState().tiles, itemId);

  const analytics = {
    ...incomingAnalytics,
    location_in_layout: LOCATION_IN_LAYOUT.MANIFEST_DROPDOWN,
  };

  const onEmailDialog = (ev) => {
    ev.preventDefault();
    setIsEmailDialogOpen(true);
  };

  const onShareToFacebook = (ev) => {
    ev.preventDefault();
    ShareActions.shareItemToPlatform(SHARING_PLATFORM.FACEBOOK, manifest, analytics, user);
  };

  const onShareToTwitter = (ev) => {
    ev.preventDefault();
    ShareActions.shareItemToPlatform(SHARING_PLATFORM.TWITTER, manifest, analytics, user);
  };

  const onShareToWhatsApp = (ev) => {
    ev.preventDefault();
    ShareActions.shareItemToPlatform(SHARING_PLATFORM.WHATSAPP, manifest, analytics, user);
  };

  return (
    <>
      <DropdownSharingButtons
        onShareToEmail={onEmailDialog}
        onShareToFacebook={onShareToFacebook}
        onShareToTwitter={onShareToTwitter}
        onShareToWhatsApp={onShareToWhatsApp}
      />
      <ShareToEmailContainer
        itemId={itemId}
        analytics={analytics}
        isVisible={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
      />
    </>
  );
};

DropdownSharingButtonsContainer.propTypes = {
  itemId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object,
};

DropdownSharingButtonsContainer.defaultProps = {
  analytics: {},
};

export default DropdownSharingButtonsContainer;
