import React from 'react';
import PropTypes from 'prop-types';

function Dislike({ className, fill }) {
  return (
    <svg width="30px" height="28px" viewBox="0 0 30 28" className={className}>
      <g id="Dropdown" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" fillRule="nonzero" fill={fill}>
          <path
            d="M27.5771645,27.1629509 C27.9676888,27.5534752 27.9676888,28.1866402 27.5771645,28.5771645 C27.1866402,28.9676888 26.5534752,28.9676888 26.1629509,28.5771645 L21.627293,24.0415066 C20.1586891,25.3261572 18.4550508,26.7576129 16.4854105,28.3842972 L16,28.785112 L15.5145895,28.3842972 C4.88021264,19.6015899 2,16.5099495 2,11.4880253 C2,9.52167097 2.7513422,7.72990466 3.97820264,6.3924162 L0.707106781,3.12132034 C0.316582489,2.73079605 0.316582489,2.09763107 0.707106781,1.70710678 C1.09763107,1.31658249 1.73079605,1.31658249 2.12132034,1.70710678 L5.52221251,5.10799895 L6.99655748,6.57077471 L21.7045709,21.2903574 L23.1293335,22.7035507 L27.5771645,27.1629509 Z M23.1741887,19.9199788 C27.0505531,16.1883129 28,14.1572631 28,11.4880253 C28,8.45751611 25.5764521,6 22.6154754,6 C21.0909676,6 19.9083979,6.49052747 18.7976371,7.43731418 C18.7354303,7.50483779 17.8028846,8.51634536 16,10.4718369 C14.2202709,8.52634793 13.3153535,7.53870993 13.285248,7.5089229 C12.1503107,6.51527214 10.9470113,6 9.38452452,6 C9.34148829,6 9.29856559,6.00051915 9.25576221,6.00155167 L7.50099853,4.24678863 C8.10250636,4.08578538 8.73382217,4 9.38452452,4 C11.7362336,4 13.3813538,4.93596563 14.5988504,6.0007894 C14.5740553,6.02782693 15.0411052,6.5054833 16,7.43375848 C16.959294,6.50583052 17.4264298,6.02809904 17.4014073,6.00056407 C18.6188782,4.93583359 20.2639323,4 22.6154754,4 C26.6873007,4 30,7.35912979 30,11.4880253 C30,14.8704267 28.6934244,17.3771769 24.5910967,21.3368868 L23.1741887,19.9199788 Z M5.39418234,7.8083959 C4.52880642,8.78186523 4,10.0724331 4,11.4880253 C4,15.3562213 5.99394458,17.8841512 15.9999997,26.1896973 C17.5988973,24.8625279 18.9932151,23.6828833 20.207508,22.6217216 L5.39418234,7.8083959 Z"
            id="heart-outline"
          />
        </g>
      </g>
    </svg>
  );
}

Dislike.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Dislike.defaultProps = {
  fill: 'currentColor',
  className: '',
};

export default Dislike;
