// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdownOption-ReBPV{border-bottom:1px solid rgba(0,0,0,0.1);margin:0 15px;padding:5px 0 10px;width:calc(100% - 30px);transition:opacity 0.3s ease-out}.isLoading-38nOY{opacity:0.2}.button-1CfCx{font-size:14px;font-family:\"GT-Walsheim\",sans-serif;text-align:left;color:#948784;display:block;width:100%;padding:0}.button-1CfCx:hover{color:#333}.content-169u7{align-items:center}.icon-1dngg{width:22px;height:22px;margin-right:8px;top:-1px;position:relative}.iconDisliked-xu0NP{color:#ff4453}\n", ""]);
// Exports
exports.locals = {
	"dropdownOption": "dropdownOption-ReBPV",
	"isLoading": "isLoading-38nOY",
	"button": "button-1CfCx",
	"content": "content-169u7",
	"icon": "icon-1dngg",
	"iconDisliked": "iconDisliked-xu0NP"
};
module.exports = exports;
