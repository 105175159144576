import React, { useEffect } from 'react';
import { func, string, bool, shape } from 'prop-types';
import ShareToEmail from 'components/dialogues/ShareToEmail';
import Analytics from 'instances/analytics';
import ShareStore from 'stores/ShareStore';
import ShareActions from 'actions/ShareActions';
import useUser from 'hooks/useUser';
import useAlt from 'hooks/useAlt';

const ShareToEmailContainer = ({
  itemId,
  isVisible,
  onClose: incomingOnClose,
  analytics,
  defaultMessage,
}) => {
  const { status } = useAlt([ShareStore], ([state]) => state);
  const user = useUser();

  useEffect(() => {
    if (isVisible) {
      ShareActions.resetStatus();
      Analytics.track('Share Email: Open dialogue', analytics);
    }
  }, [isVisible]);

  const onSubmit = (to, message) => {
    ShareActions.shareItemToEmail(user.id, itemId, to, message, analytics);
  };

  const onClose = () => {
    ShareActions.resetStatus();
    incomingOnClose();
  };

  return isVisible ? (
    <ShareToEmail
      onSubmit={onSubmit}
      status={status}
      onClose={onClose}
      defaultMessage={defaultMessage}
    />
  ) : null;
};

ShareToEmailContainer.propTypes = {
  onClose: func.isRequired,
  itemId: string.isRequired,
  defaultMessage: string,
  analytics: shape({}).isRequired,
  isVisible: bool.isRequired,
};

ShareToEmailContainer.defaultProps = {
  defaultMessage: undefined,
};

export default ShareToEmailContainer;
