// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown-2OBmt{min-width:260px;margin-top:0 !important;padding-bottom:0;white-space:normal}\n", ""]);
// Exports
exports.locals = {
	"dropdown": "dropdown-2OBmt"
};
module.exports = exports;
