import React from 'react';
import { bool, func, string, object } from 'prop-types';
import { translate } from 'instances/i18n';
import { DropdownOption, Columns } from '@blendle/lego';
import classNames from 'classnames';
import DislikeIcon from 'components/icons/Dislike';
import DislikedIcon from 'components/icons/Disliked';
import CSS from './style.scss';

function ItemFeedbackOption({
  didDislikeItem,
  onToggleDislike,
  className,
  itemId,
  onFeedback,
  analytics,
  isLoading,
  ...props
}) {
  return (
    <DropdownOption
      className={classNames(CSS.dropdownOption, didDislikeItem && CSS.didDislikeItem, className)}
      {...props}
    >
      <button
        className={classNames(CSS.button, isLoading && CSS.isLoading, className)}
        onClick={onToggleDislike}
        disabled={isLoading}
      >
        {didDislikeItem ? (
          <Columns className={CSS.content}>
            <DislikedIcon className={`${CSS.icon} ${CSS.iconDisliked}`} />
            <span>{translate('item.preference.disliked')}</span>
          </Columns>
        ) : (
          <Columns className={CSS.content}>
            <DislikeIcon className={CSS.icon} />
            <span>{translate('item.preference.dislike')}</span>
          </Columns>
        )}
      </button>
    </DropdownOption>
  );
}

ItemFeedbackOption.propTypes = {
  itemId: string.isRequired,
  isLoading: bool,
  didDislikeItem: bool.isRequired,
  onToggleDislike: func.isRequired,
  onFeedback: func,
  className: string,
  analytics: object, // eslint-disable-line react/forbid-prop-types
};

ItemFeedbackOption.defaultProps = {
  className: '',
  onFeedback: () => undefined,
  analytics: {},
  isLoading: false,
};

export default ItemFeedbackOption;
