// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-MjmNY{margin:0 15px;border-bottom:1px solid #eaeaea;padding:5px 0;background:white}.shareButton-Xqv0h{font-family:\"GT-Walsheim\",sans-serif;width:100%;cursor:pointer;color:#948784;font-size:14px;padding:10px 0;text-align:left}.shareButton-Xqv0h:hover{color:#333}.shareButton-Xqv0h:before{display:inline-block;width:30px;font-size:15px;text-align:left;vertical-align:middle}.email-2Ijug:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA0F\"}.twitter-2F4zJ:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA21\";color:#55acee}.facebook-2zLL5:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA10\";color:#3a559b}.whatsapp-1HZ6f:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA24\";color:#4dc247}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-MjmNY",
	"shareButton": "shareButton-Xqv0h",
	"email": "email-2Ijug",
	"twitter": "twitter-2F4zJ",
	"facebook": "facebook-2zLL5",
	"whatsapp": "whatsapp-1HZ6f"
};
module.exports = exports;
