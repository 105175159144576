import React from 'react';
import PropTypes from 'prop-types';

function Disliked({ className, fill }) {
  return (
    <svg width="30px" height="28px" viewBox="0 0 30 28" className={className}>
      <g id="Dropdown" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Artboard"
          transform="translate(-148.000000, -196.000000)"
          fillRule="nonzero"
          fill={fill}
        >
          <g id="Group-Copy" transform="translate(96.000000, 195.000000)">
            <path
              d="M79.1629509,26.7487373 C79.5534752,27.1392616 79.5534752,27.7724266 79.1629509,28.1629509 C78.7724266,28.5534752 78.1392616,28.5534752 77.7487373,28.1629509 L73.2130795,23.627293 C71.7444755,24.9119437 70.0408372,26.3433994 68.071197,27.9700837 L67.5857864,28.3708985 L67.1003759,27.9700837 C56.4659991,19.1873763 53.5857864,16.095736 53.5857864,11.0738117 C53.5857864,9.10745741 54.3371286,7.3156911 55.5639891,5.97820264 L52.2928932,2.70710678 C51.9023689,2.31658249 51.9023689,1.68341751 52.2928932,1.29289322 C52.6834175,0.902368927 53.3165825,0.902368927 53.7071068,1.29289322 L57.1079989,4.69378539 L58.5823439,6.15656115 L73.2903574,20.8761438 L74.7151199,22.2893372 L79.1629509,26.7487373 Z M59.086785,3.83257507 C59.6882928,3.67157182 60.3196086,3.58578644 60.970311,3.58578644 C63.32202,3.58578644 64.9671402,4.52175207 66.1846368,5.58657584 C66.1598418,5.61361337 66.6268916,6.09126973 67.5857864,7.01954492 C68.5450805,6.09161695 69.0122162,5.61388548 68.9871937,5.5863505 C70.2046647,4.52162003 71.8497188,3.58578644 74.2012618,3.58578644 C78.2730871,3.58578644 81.5857864,6.94491623 81.5857864,11.0738117 C81.5857864,14.4562131 80.2792109,16.9629633 76.1768831,20.9226732 L59.086785,3.83257507 Z"
              id="heart-outline-active"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

Disliked.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Disliked.defaultProps = {
  fill: 'currentColor',
  className: '',
};

export default Disliked;
