// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemDetails-1EaP-{font-size:12px;padding:15px;color:#333;padding-bottom:10px}.label-3SoRB{flex-basis:75px;color:#948784}\n", ""]);
// Exports
exports.locals = {
	"itemDetails": "itemDetails-1EaP-",
	"label": "label-3SoRB"
};
module.exports = exports;
