// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".triggerButton-oLLs2{height:42px;width:42px;cursor:pointer;color:#fff}.triggerButton-oLLs2:hover{color:#f4f4f4}.arrowIcon-PJ1-6{color:inherit;transition:transform 0.1s ease-out;backface-visibility:hidden}.arrowIcon-PJ1-6.cappucino-37cq3{color:#948784}.isOpen-3bWDa{transform:rotate(180deg)}\n", ""]);
// Exports
exports.locals = {
	"triggerButton": "triggerButton-oLLs2",
	"arrowIcon": "arrowIcon-PJ1-6",
	"cappucino": "cappucino-37cq3",
	"isOpen": "isOpen-3bWDa"
};
module.exports = exports;
